import React, { useContext } from "react"
import styled from "styled-components"
import StyledImage from "../../StyledImage"
import { MEDIA_TYPE_VIDEO, getConfigValue, convertToSlug } from "../../../constants/props"
import * as St from "../../Styled"
import { AppContext } from "../../../contexts/AppContext"

const VideoCardWrappper = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  & div {
    border: none;
  }
`
const VideoCard = props => {
  const { updatePlayer, device } = useContext(AppContext)
  const station = {
    id: props.station && props.station.id ? props.station.id : null,
    slug: convertToSlug(props.station && props.station.title ? props.station.title : null)
  }
  return (
    <VideoCardWrappper> 
      <St.VideoSliderImageWrapper>
        <StyledImage
          ismobile={props.ismobile}
          image={props.image && props.image.url ? props.image.url : ""}
          width={"100%"}
          height={"100%"}
          premium={props.premium}
          borderRadius={"0px"}
          imageAlt={props.title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            updatePlayer({
              type: MEDIA_TYPE_VIDEO,
              id: props.id,
              title: props.title,
              preview: props.image.url,
              uri: props.uri, 
              playlist: props.playlist,
              station: station, // Just to clear that station is the owner of playing video
              artist: null,
            })
          }}
        />
      </St.VideoSliderImageWrapper>
      <St.VideoSliderInfoWrapper>
        <St.VideoSliderTitle>{props.title}</St.VideoSliderTitle>
        <St.VideoSliderReadMoreSection
          string={props.about}
          offset={getConfigValue(device, "videos.slider.textOffset")}
        >
          {props.about ? props.about : ""}
        </St.VideoSliderReadMoreSection>
      </St.VideoSliderInfoWrapper>
    </VideoCardWrappper>
  )
}

export default VideoCard
