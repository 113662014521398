import React, { useContext } from "react"
import styled from "styled-components"
import StyledImage from "../../StyledImage"
import moment from "moment-timezone"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  convertToSlug,
  DATA_TYPE_STATION_PODCAST,
  DATA_TYPE_ARTIST_PODCAST,
} from "../../../constants/props"
import {
  stationPodcastsShareURL,
  artistPodcastsShareURL,
} from "../../../constants/share"
import { TAB } from "../../../constants/breakpoints"
import LikeAndShare from "../../LikeAndShare"
import { AppContext } from "../../../contexts/AppContext"
import * as St from "../../Styled"
import ReadMorePara from "../../ReadMorePara"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`
const PodcastCard = ({ podcast, dataType }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, device, selectedCountry } = useContext(AppContext)

  if (!podcast) return null

  const { id, uri, title, updatedAt, presenter, show, about } = podcast
  let nameSlug = ""
  let ownerID = ""
  let countrySlug = ""

  if (dataType === DATA_TYPE_STATION_PODCAST) {
    let station = podcast.station ? podcast.station : {}
    nameSlug = convertToSlug(station.title)
    ownerID = station.id
    countrySlug = station.country ? convertToSlug(station.country.title) : ""
  } else if (dataType === DATA_TYPE_ARTIST_PODCAST) {
    let artist = podcast.artist ? podcast.artist : {}
    nameSlug = convertToSlug(artist.title)
    countrySlug = artist.country ? convertToSlug(artist.country.title) : ""
    ownerID = artist.id
  }

  const image =
    podcast.image && podcast.image.url
      ? podcast.image.url.startsWith("http")
        ? podcast.image.url
        : basePrefix + podcast.image.url
      : ""
  return (
    <St.PodcastListWrapper>
      <St.PodcastWrapper>
        <St.PodcastListImageWrapper>
          <StyledImage
            image={image}
            width={"100%"}
            height={"100%"}
            shaded={true}
            imageAlt={title}
            borderRadius={
              getConfigValue(device, "podcasts.list.image.borderRadius") + "px"
            }
            onClickPlay={event => {
              event.preventDefault()
              event.stopPropagation()
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                uri,
                preview:
                  podcast.image && podcast.image.url ? podcast.image.url : "",
                playlist: [],
              })
            }}
          />
        </St.PodcastListImageWrapper>
        <St.PodcastListRightTextSection>
          <St.PodcastInfoWrapper>
            <St.PodcastTitleWrapper>
              <St.PodcastTitle>{title}</St.PodcastTitle>
              <St.PodcastDate>
                {moment(updatedAt).format("DD MMMM YYYY")}
              </St.PodcastDate>
            </St.PodcastTitleWrapper>
            {presenter && presenter.title ? (
              <St.PodcastListSingleLineText>
                <div>Presenter: </div> <span>{presenter.title}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {show && show.title ? (
              <St.PodcastListSingleLineText>
                <div>Show: </div> <span> {show.title}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {podcast.about && (
              <St.PodcastListMultiLineText>
                <div>About:</div>
                <ReadMorePara
                  string={about}
                  offset={getConfigValue(device, "podcasts.list.textOffset")}
                />
              </St.PodcastListMultiLineText>
            )}
          </St.PodcastInfoWrapper>
          <St.PodcastLikeShareSection>
            <LikeShare>
              <LikeAndShare
                shareURL={
                  selectedCountry && selectedCountry.id
                    ? dataType === DATA_TYPE_STATION_PODCAST
                      ? stationPodcastsShareURL(
                          ownerID,
                          convertToSlug(title),
                          nameSlug,
                          countrySlug,
                          image
                        )
                      : artistPodcastsShareURL(
                          ownerID,
                          convertToSlug(title),
                          nameSlug,
                          countrySlug,
                          image
                        )
                    : null
                }
                id={id}
                shareID={"allpodcasts_" + id}
                likeType={dataType}
              />
            </LikeShare>
          </St.PodcastLikeShareSection>
        </St.PodcastListRightTextSection>
      </St.PodcastWrapper>
    </St.PodcastListWrapper>
  )
}

export default PodcastCard
