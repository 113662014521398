import React, { useContext } from "react"
import StyledImage from "../../../components/StyledImage"
import ReadMorePara from "../../../components/ReadMorePara"
import * as St from "../../Styled"
import { TAB } from "../../../constants/breakpoints"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  getConfigValue,
  DATA_TYPE_STATION_VIDEO,
  DATA_TYPE_ARTIST_VIDEO,
} from "../../../constants/props"

import {
  stationVideoDetailsShareURL,
  artistVideoDetailsShareURL,
} from "../../../constants/share"
import styled from "styled-components"
import LikeAndShare from "../../LikeAndShare"
import { AppContext } from "../../../contexts/AppContext"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`

const FeaturedVideo = ({ data, dataType, updatePlayer, ctxPlayer, device }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const {
    id,
    title,
    uri,
    subtitles,
    image,
    about,
    viewed,
    station,
    artist,
  } = data

  const videoImage = image && image.url
    ? image.url.startsWith("http")
      ? image.url
      : basePrefix + image.url
    : ""

  let nameSlug = ""
  let ownerID = ""

  const isPlaying =
    ctxPlayer.id === id &&
    (dataType === DATA_TYPE_STATION_VIDEO ||
      dataType === DATA_TYPE_ARTIST_VIDEO) &&
    ctxPlayer.playing
      ? true
      : false

  if (dataType === DATA_TYPE_STATION_VIDEO && station) {
    nameSlug = convertToSlug(station.title)
    ownerID = station.id
  } else if (dataType === DATA_TYPE_ARTIST_VIDEO && artist) {
    nameSlug = convertToSlug(artist.title)
    ownerID = artist.id
  }

  return (
    <St.VideoFeaturedWrapper>
      <St.VideoFeaturedImageWrapper>
        <StyledImage
          borderRadius="0px"
          image={videoImage}
          width={"100%"}
          height={"100%"}
          playing={isPlaying}
          imageAlt={title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_VIDEO,
                id,
                title,
                uri,
                subtitles,
                preview:
                  image && image.url
                    ? image.url.startsWith("http")
                      ? image.url
                      : basePrefix + image.url
                    : "",
                playlist: [],
                videoMode: VIDEO_MODE_MAXIMIZED,
                about,
                station: dataType === DATA_TYPE_STATION_VIDEO ? station : null,
                artist: dataType === DATA_TYPE_ARTIST_VIDEO ? artist : null,
                viewed: viewed != null ? viewed : 0,
                dataType,
                playing: true,
              })
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.VideoFeaturedImageWrapper>
      <St.VideoFeaturedContainer
        videoPlaying={ctxPlayer.videoMode === VIDEO_MODE_MAXIMIZED}
      >
        <St.VideoFeaturedBottomTextSection>
          <St.VideoFeaturedColumnText>
            <St.VideoFeaturedTitle> {data.title} </St.VideoFeaturedTitle>
            <St.VideoInfoLabel>
              {subtitles && subtitles.length ? subtitles[0] + subtitles[1] : ""}
            </St.VideoInfoLabel>
            <ReadMorePara
              string={about}
              offset={getConfigValue(device, "box.videos.featured.textOffset")}
            ></ReadMorePara>
          </St.VideoFeaturedColumnText>
          <St.VideoFeaturedColumnText
            style={{ alignItems: "flex-end", flexShrink: 0 }}
          >
            <St.VideoViews>
              <span>{viewed != null ? viewed : "0"} </span>{" "}
              <span>{viewed === 1 ? "View" : "Views"}</span>
            </St.VideoViews>
            <St.FeaturedSocialIconSection>
              <LikeShare>
                <LikeAndShare
                  shareURL={
                    dataType === DATA_TYPE_STATION_VIDEO
                      ? stationVideoDetailsShareURL(
                          id,
                          convertToSlug(title),
                          ownerID,
                          nameSlug,
                          videoImage
                        )
                      : artistVideoDetailsShareURL(
                          id,
                          convertToSlug(title),
                          ownerID,
                          nameSlug,
                          videoImage
                        )
                  }
                  id={id}
                  likeType={dataType}
                  shareID={"featuredvideo_" + id}
                />
              </LikeShare>
            </St.FeaturedSocialIconSection>
          </St.VideoFeaturedColumnText>
        </St.VideoFeaturedBottomTextSection>
      </St.VideoFeaturedContainer>
    </St.VideoFeaturedWrapper>
  )
}

const Video = ({ video, dataType }) => {
  const { updatePlayer, device, ctxPlayer } = useContext(AppContext)
  return (
    <St.VideoFeaturedBox
      videoPlaying={false}
      style={{ marginTop: "15px", marginBottom: "15px" }}
    >
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>MOST VIEWED</St.FeaturedBoxHeading>
        <FeaturedVideo
          device={device}
          dataType={dataType}
          data={{
            ...video,
          }}
          {...{ updatePlayer, device, ctxPlayer }}
        />
      </St.FeaturedBoxContent>
    </St.VideoFeaturedBox>
  )
}

export default Video
