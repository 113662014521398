import React, { useContext } from "react"
import StyledImage from "../../StyledImage"
import * as St from "../../Styled"
import { MEDIA_TYPE_AUDIO, getConfigValue } from "../../../constants/props"

import { AppContext } from "../../../contexts/AppContext"

const PodcastCard = props => {
  const { updatePlayer, selectedCountry, device } = useContext(AppContext)
  return (
    <St.PodcastSliderWrappper>
      <St.PodcastSliderImageWrapper>
        <StyledImage
          image={props.image && props.image.url ? props.image.url : ""}
          width={"100%"}
          height={"100%"}
          premium={props.premium}
          imageAlt={props.title}
          borderRadius={
            getConfigValue(device, "podcasts.slider.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            updatePlayer({
              type: MEDIA_TYPE_AUDIO, // podcasts are just audios
              id: props.id,
              title: props.title,
              preview: props.image.url,
              subtitle: props.station && props.station.title,
              uri: props.uri,
              playlist: props.playlist,
            })
          }}
        />
      </St.PodcastSliderImageWrapper>
      <St.PodcastSliderInfoWrapper>
        {selectedCountry && (
          <St.PodcastsSliderTitle>{props.title}</St.PodcastsSliderTitle>
        )}

        <St.PodcastSliderReadMoreSection
          string={props.about}
          offset={getConfigValue(device, "podcasts.slider.textOffset")}
        />
      </St.PodcastSliderInfoWrapper>
    </St.PodcastSliderWrappper>
  )
}

export default PodcastCard
