import React from "react"
import PodcastCard from "./PodcastCard"

import * as St from "../../Styled"

const Podcast = ({ podcast, dataType }) => {
  return (
    <St.PodcastAllWrapper>
      <PodcastCard podcast={podcast} dataType={dataType} />
    </St.PodcastAllWrapper>
  )
}

export default Podcast
