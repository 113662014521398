import React, { useContext } from "react"
import ContentYourLikes from "../components/ContentYourLikes"
import { AppContext } from "../contexts/AppContext"
import NotAuthorized from "../components/NotAuthorized"

const YourLikes = () => {
  const { userState } = useContext(AppContext)

  return (
    <>
      {userState.isLoggedIn ? (
        <ContentYourLikes userID={userState.id} />
      ) : (
        <NotAuthorized>
          You are not authorized to see contents of this page
        </NotAuthorized>
      )}
    </>
  )
}

export default YourLikes
