import React, { useContext } from "react"
import styled from "styled-components"
import StyledImage from "../../StyledImage"
import * as St from "../../Styled"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  DATA_TYPE_STATION,
} from "../../../constants/props"
import { stationDetailsShareURL } from "../../../constants/share"
import LikeAndShare from "../../LikeAndShare"
import { AppContext } from "../../../contexts/AppContext"
import { convertToSlug, stationDetailsURL } from "../../../constants/props"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../../constants/breakpoints"
import { Link } from "gatsby"

const StationTitle = styled(Link)`
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    cursor: pointer;
    color: #fff;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(45px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
`
const DisableOverlay = styled.div`
  position: absolute;
  background: #ffffffa1;
  z-index: 9998;
  & span {
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #454343c7;
    text-align: center;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1.5px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.image.widthHeight}vw;
    height: ${MOBILE.stations.grid.image.widthHeight}vw;
    border-radius: ${MOBILE.podcasts.slider.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
    height: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
    border-radius: ${LANDSCAPE.podcasts.slider.image.borderRadius}px;
    & span {
      font-size: 12px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.image.widthHeight}px;
    height: ${TAB.stations.grid.image.widthHeight}px;
    border-radius: ${TAB.podcasts.slider.image.borderRadius}px;
    & span {
      font-size: 16px;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.image.widthHeight}px;
    height: ${MEDIUM.stations.grid.image.widthHeight}px;
    border-radius: ${MEDIUM.podcasts.slider.image.borderRadius}px;
    & span {
      font-size: 20px;
    }
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.image.widthHeight}px;
    height: ${LARGE.stations.grid.image.widthHeight}px;
    border-radius: ${LARGE.podcasts.slider.image.borderRadius}px;
  }
`

const basePrefix = `${process.env.STRAPI_SERVER_URL}`
const baseAmazonPrefix = `${process.env.AMAZON_BASE_PREFIX}`

const StationCard = ({
  idxs,
  station: {
    id,
    title,
    image,
    streamuri,
    premium,
    presenters,
    country,
    available,
    slug,
  },
}) => {
  const { updatePlayer, device } = useContext(AppContext)
  let stationImage =
    image && image.url
      ? image.url.startsWith("http")
        ? image.url
        : basePrefix + image.url
      : ""

  if (!stationImage || stationImage.trim().length === 0) {
    // show a default station image
    stationImage = baseAmazonPrefix + "/static/default-station-image.jpg"
  }

  return (
    <St.StationSliderWrappper>
      <St.StationSliderImageWrapper>
        {!available && (
          <DisableOverlay>
            <span>Currently Offline</span>
          </DisableOverlay>
        )}
        <StyledImage
          image={stationImage}
          width={"100%"}
          height={"100%"}
          premium={premium}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "podcasts.slider.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            updatePlayer({
              type: MEDIA_TYPE_AUDIO, // podcasts are just audios
              id: id,
              title: title,
              preview: image ? image.url : "",
              subtitle: title,
              streamuri,
            })
          }}
        />
      </St.StationSliderImageWrapper>
      <St.StationSliderBottomTextSection>
        <StationTitle to={stationDetailsURL(slug)}>
          <St.StationSliderTitle>{title}</St.StationSliderTitle>
        </StationTitle>

        {presenters && presenters.length && presenters[0].title ? (
          <St.StationSliderSingleLineText>
            <span>Presenter: {presenters[0].title}</span>
          </St.StationSliderSingleLineText>
        ) : null}
        <St.StationSliderRow>
          <St.StationSliderSingleLineText style={{ flexShrink: 1 }}>
            <span>{country && country.title}</span>
          </St.StationSliderSingleLineText>
          <LikeShare>
            <LikeAndShare
              shareURL={stationDetailsShareURL(slug)}
              id={id}
              likeType={DATA_TYPE_STATION}
              shareID={"stationonrepeat_" + id}
              position={idxs === 0 ? ["top", "middle"] : ["top", "left"]}
            />
          </LikeShare>
        </St.StationSliderRow>
      </St.StationSliderBottomTextSection>
    </St.StationSliderWrappper>
  )
}

export default StationCard
