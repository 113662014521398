import React, { useContext } from "react"
import styled from "styled-components"
import { AppContext } from "../../contexts/AppContext"
import * as St from "../Styled"
import PodcastHList from "./PodcastHList"
import Podcast from "./Podcast"
import Video from "./Video"
import VideoHList from "./VideoHList"
import LikeAndShare from "../LikeAndShare"
import { navigate } from "gatsby-link"
import moment from "moment"
import {
  convertToSlug,
  DATA_TYPE_STATION,
  DATA_TYPE_ARTIST_PODCAST,
  DATA_TYPE_ARTIST_VIDEO,
  DATA_TYPE_STATION_VIDEO,
  DATA_TYPE_STATION_PODCAST,
  APPSETTING_USER_DATA_DAYS,
} from "../../constants/props"

import { LANDSCAPE } from "../../constants/breakpoints"
import { stationDetailsShareURL } from "../../constants/share"
import Query from "../Query"
import { QUERY_USER_DATA, QUERY_USER_DATA_COUNT } from "../../queries/page/user"
import auth from "../../auth/auth"
import StationHList from "./StationHList"

const PodcastHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`
const VideoHListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin-top: 30px;
`
const LinkPage = styled.div`
  color: #fff;
`
const RadioList = styled.div`
  display: flex;
`
const RadioName = styled.span`
  display: flex;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 14px;
  padding-left: 1rem;
  line-height: 1;
  cursor: pointer;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale});
      transition: all ${LANDSCAPE.linkHover.transition}s;
      & h3 {
        color: ${LANDSCAPE.linkHover.color};
      }
    }
  }
`
const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
`

const UserLikesContent = ({ userID }) => {
  const { selectedCountry, userLikes, appSettings } = useContext(AppContext)

  const stationLikes =
    userLikes && userLikes.station && userLikes.station.length > 0
      ? userLikes.station
      : null

  let hasNoData = true

  if (stationLikes) hasNoData = false

  const allPodcast =
    userLikes && userLikes.stationPodcast
      ? userLikes.stationPodcast.concat(userLikes.artistPodcast)
      : null

  if (allPodcast && allPodcast.length > 0) hasNoData = false

  const allVideo =
    userLikes && userLikes.artistVideo
      ? userLikes.stationVideo.concat(userLikes.artistVideo)
      : null

  if (allVideo && allVideo.length > 0) hasNoData = false

  const countryNameSlug =
    selectedCountry && selectedCountry.name
      ? convertToSlug(selectedCountry.name)
      : "all"

  /**
   * Only recent user data would be shown in your likes section and not all data that is there
   */

  let baseMoment = moment().subtract(
    appSettings && appSettings[APPSETTING_USER_DATA_DAYS]
      ? appSettings[APPSETTING_USER_DATA_DAYS]
      : 30,
    "day"
  )

  console.log("[BASE MOMENT] ", baseMoment)

  return (
    <Query
      query={QUERY_USER_DATA_COUNT}
      variables={{
        where: {
          user: userID,
        },
      }}
      token={auth.getToken()}
      fetchPolicy="network-only"
    >
      {({
        data: {
          userDataConnection: { aggregate },
        },
      }) => {
        return (
          <St.ContentParent>
            <St.AdWrapper>
              <St.LeftAdSection />
              <St.LeftAd2Section />
            </St.AdWrapper>

            <St.ContentWrapper>
              <St.CenterContentSection>
                {aggregate.count > 0 && (
                  <Query
                    query={QUERY_USER_DATA}
                    variables={{
                      where: {
                        user: userID,
                        type: DATA_TYPE_STATION,
                        lastPlayed_gte: baseMoment,
                      },
                      limit: 3,
                      sort: "playedCount:desc",
                    }}
                    token={auth.getToken()}
                    fetchPolicy="network-only"
                  >
                    {({ data: { userData } }) => {
                      return (
                        userData &&
                        userData.length > 0 && (
                          <St.FeaturedBox
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          >
                            <St.FeaturedBoxContent>
                              <St.FeaturedBoxHeading>
                                RADIO STATIONS ON REPEAT
                              </St.FeaturedBoxHeading>
                              <StationHList data={userData} />
                            </St.FeaturedBoxContent>
                          </St.FeaturedBox>
                        )
                      )
                    }}
                  </Query>
                )}

                {stationLikes && (
                  <St.CenterContentWrapper>
                    <PodcastHListWrapper>
                      <St.UserDataHeading>
                        <LinkPage>YOUR LIKES : RADIO</LinkPage>
                      </St.UserDataHeading>

                      {stationLikes.map((station, idx) => (
                        <RadioList key={idx}>
                          <LikeShare>
                            <LikeAndShare
                              shareURL={stationDetailsShareURL(station.data.slug)}
                              id={station.data.id}
                              likeType={DATA_TYPE_STATION}
                              shareID={"allstation_" + station.data.id}
                              openInMiddle={idx % 2 === 0}
                            />
                          </LikeShare>
                          <RadioName
                            onClick={() => {
                              navigate(stationDetailsShareURL(station.data.slug))
                            }}
                          >
                            {station.data.title}
                          </RadioName>
                        </RadioList>
                      ))}
                    </PodcastHListWrapper>
                  </St.CenterContentWrapper>
                )}

                {aggregate.count > 0 && (
                  <Query
                    query={QUERY_USER_DATA}
                    variables={{
                      where: {
                        user: userID,
                        type_in: [
                          DATA_TYPE_STATION_PODCAST,
                          DATA_TYPE_ARTIST_PODCAST,
                        ],
                        lastPlayed_gte: baseMoment,
                      },
                      limit: 1,
                      sort: "playedCount:desc",
                    }}
                    token={auth.getToken()}
                    fetchPolicy="network-only"
                  >
                    {({ data: { userData } }) => {
                      return (
                        userData &&
                        userData.length > 0 &&
                        userData[0].mediacontent && (
                          <St.FeaturedBox
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          >
                            <St.FeaturedBoxContent>
                              <St.FeaturedBoxHeading>
                                THE PODCAST YOU CANT GET ENOUGH OF
                              </St.FeaturedBoxHeading>
                              <Podcast
                                podcast={userData[0].mediacontent}
                                dataType={userData[0].type}
                              ></Podcast>
                            </St.FeaturedBoxContent>
                          </St.FeaturedBox>
                        )
                      )
                    }}
                  </Query>
                )}

                {allPodcast && allPodcast.length > 0 && (
                  <St.CenterContentWrapper>
                    <PodcastHListWrapper>
                      <St.UserDataHeading>
                        <LinkPage>YOUR LIKES : PODCAST</LinkPage>
                      </St.UserDataHeading>
                      <PodcastHList data={allPodcast} />
                    </PodcastHListWrapper>
                  </St.CenterContentWrapper>
                )}

                {aggregate.count > 0 && (
                  <Query
                    query={QUERY_USER_DATA}
                    variables={{
                      where: {
                        user: userID,
                        type_in: [
                          DATA_TYPE_STATION_VIDEO,
                          DATA_TYPE_ARTIST_VIDEO,
                        ],
                        lastPlayed_gte: baseMoment,
                      },
                      limit: 1,
                      sort: "playedCount:desc",
                    }}
                    token={auth.getToken()}
                    fetchPolicy="network-only"
                  >
                    {({ data: { userData } }) => {
                      return (
                        userData &&
                        userData.length > 0 &&
                        userData[0].mediacontent && (
                          <Video
                            video={userData[0].mediacontent}
                            dataType={userData[0].type}
                          ></Video>
                        )
                      )
                    }}
                  </Query>
                )}

                {allVideo && allVideo.length > 0 && (
                  <St.CenterContentWrapper>
                    <VideoHListWrapper>
                      <St.UserDataHeading>
                        <LinkPage>YOUR LIKES : VIDEO</LinkPage>
                      </St.UserDataHeading>
                      <VideoHList data={allVideo} />
                    </VideoHListWrapper>
                  </St.CenterContentWrapper>
                )}

                {hasNoData && aggregate.count === 0 && (
                  <St.ContentSection>
                    <St.NoDataMessage style={{ marginTop: "20vh" }}>
                      No likes to show
                    </St.NoDataMessage>
                  </St.ContentSection>
                )}
              </St.CenterContentSection>
              <St.BottomAdSection />
            </St.ContentWrapper>
            <St.AdWrapper>
              <St.RightAdSection />
              <St.RightAd2Section />
            </St.AdWrapper>
          </St.ContentParent>
        )
      }}
    </Query>
  )
}

export default UserLikesContent
